*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  direction: inherit;
}
html {
  font-size: 16px;
  @media (max-width: 1440px) {
    font-size: 11px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.25) {
    font-size: 12px;
    @media (max-width: 1440px) {
      font-size: 8.25px;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.5) {
    font-size: 13px;
  }
}
a {
  text-decoration: none;
}

button {
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: 0.15s;

  &:focus {
    outline: none;
  }
}

html {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background: #0c282b;
  
}

#swiper {
  .swiper-pagination {
    text-align: left;
    padding-left: 30px;
  }
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
    background: transparent;
    border: 1px solid #fff;
    opacity: 1;
    margin-right: 20px !important;
    &:last-child {
      margin-right: 0 !important;
    }
    &.swiper-pagination-bullet-active {
      background: #fff !important;
    }
  }
  @media (max-width: 768px) {
    .swiper-pagination {
      text-align: center;
      bottom: 40px;
      padding-left: 0;
    }
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  overflow: hidden !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@font-face {
  font-family: "Colbo-bold";
  src: url("/fonts/COLBO-BlackSemiExtended.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Colbo-Regular";
  src: url("/fonts/COLBO-RegularCondensed.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Colbo-Ultra-bold";
  src: url("/fonts/COLBO-UltraBlackExtended.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Colbo-BoldSemiCondensed";
  src: url("/fonts/COLBO-BoldSemiCondensed.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Colbo-light-con";
  src: url("/fonts/COLBO-LightSemiExtended.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Colbo-LightSemiExtended";
  src: url("./fonts/COLBO-LightSemiExtended.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Colbo-BoldExtended";
  src: url("./fonts/COLBO-BoldExtended.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Colbo-LightExtended";
  src: url("./fonts/COLBO-LightExtended.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "COLBO-RegularExtended";
  src: url("./fonts/COLBO-RegularExtended.otf") format("opentype");
  font-style: normal;
}
.font-helper {
  font-family: "Colbo-bold" !important;
  font-family: "Colbo-Regular" !important;
  font-family: "Colbo-Ultra-bold" !important;
  font-family: "Colbo-bold" !important;
  font-family: "Colbo-light-con" !important;
  font-family: "Colbo-LightExtended" !important;
  font-family: "Colbo-BoldExtended" !important;
  font-family: "Colbo-LightExtended" !important;
}
.logoFlex {
  align-self: center;
}

.clockSeconds {
  animation: rotate2 60s infinite steps(60);
}
.clockMinutes {
  animation: rotate 3600s infinite steps(60);
}
.justyify {
  text-align: justify !important;
}
@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateZ(-45deg);
  }
  100% {
    transform: rotateZ(325deg);
  }
}
.vimeo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
