.blockDoorSecond {
  position: relative;
  height: auto;
  width: 50vw;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 0.6s;
  .containerSwiper {
    height: 100%;
    width: 100%;
    
    iframe,
    video {
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      @media (min-width: 1921px) {
        height: unset;
        min-height: 100vh;
        min-width: 50vw;
      }
    }
  }
  .tabs {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    z-index: 10;
    gap: 29px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 1440px) {
      gap: 2.5vh;
    }

    > a {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 7;
      }
      &:nth-child(3) {
        order: 5;
      }
      &:nth-child(4) {
        order: 1;
      }
      &:nth-child(5) {
        order: 3;
      }
      &:nth-child(6) {
        order: 6;
      }
      &:nth-child(7) {
        order: 4;
      }
    }
  }
  @media (max-width: 768px) {
    transform: translateX(0%);
    transform: translateY(100vh);
    &.active {
      transform: translateY(0%);
    }
  }
}

.blockDoorSecond.active {
  transform: translateX(0%);
}
@media (max-width: 768px) {
  .blockDoorSecond {
    z-index: -1 !important;
    position: relative;
    width: 100%;
    .containerSwiper {
      height: 300px;
      iframe,
      video {
        position: absolute;
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
      .swiper-slide {
        overflow: hidden !important;
      }
    }
    .tabs {
      display: none;
    }
  }
}

.object {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

