.BlockLink {
  display: flex;
  direction: rtl;
  color: #ffffff;
  transition: transform 0.3s ease-in-out;
  p {
    line-height: 0.9em;
    padding-top: 1.5px;
    padding-bottom: 4px;
    font-family: "Colbo-BoldExtended", "Gotham";
  }

  &.active {
    @media (min-width: 767px) {
      &:hover {
        .BlockLinkText {
          transform: translateX(-100%);
        }
      }
    }
  }
  &.transition {
    cursor: pointer;
    transform: translateX(84px);
    @media (max-width: 1440px) {
      transform: translateX(7.77vh);
    }
    &:hover {
      transform: translateX(0px);
    }
  }
  &.currentTab {
    transform: translateX(0px) !important;
  }
  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 165px;
    width: 100vw;
    border-radius: 15px 0px 0px 0px;
    overflow: hidden;
    position: relative;
    max-height: 93px;
    & > span {
      width: 100% !important;
      height: 100% !important;
      & > img {
        width: 100% !important;
        height: auto !important;
      }
    }

    @media (max-width: 1440px) {
      max-height: 9.94vh;
      max-width: 100vh;
    }

    @media (max-width: 1000px) and (min-width: 800px) {
      max-height: 9.94vw;
      max-width: 19.27vw;
    }

  }
  &Text {
    position: absolute;
    display: flex;
    align-items: center;  
    font-size: 23px;
    font-family: "Colbo-bold", "Gotham";
    background-color: #036473;
    padding: 12px 14px 15px 4px;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 10;
    transition: transform 0.6s;
    &.active {
      transform: translateX(-100%);
    }
    @media (max-width: 1440px) {
      font-size: 16px;
      padding: 9.5px 11.2px 12px 3.2px;
      @media (min-width: 768px) {
        p {
          padding-top: 0;
          padding-bottom: 10px;
        }
      }
    }
  }
  &Sub {
    font-family: "Colbo-LightExtended", "Gotham";
    font-weight: 200;
    font-size: 13px;
    line-height: 18px;
  }
  &Photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    max-height: 93px;
    max-width: 84px;
    background: #ffffff;
    

    @media (max-width: 1440px) {
      max-height: 9.94vh;
      max-width: 7.77vh;
      padding: 1vh;
    }
    @media (max-width: 1000px) and (min-width: 800px) {
      max-height: 9.94vw;
      max-width: 7.77vw;
      padding: 1vw;
    }
  }
}

.inHeader {
  .BlockLinkSub,
  .BlockLinkPhoto {
    display: none;
  }

  .BlockLinkText {
    height: auto;
    padding: 27px 30px;
    padding-left: 0;
  }
  max-width: 140px;
  overflow: hidden;
  width: 50%;
  margin-bottom: 25px;
  
  &:last-child {
    max-width: unset;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .BlockLink {
    

    .BlockLinkText {
      height: 100%;
      padding: 15px 0;
      display: flex;
      // justify-content: center;
      font-size: 20px;
    }

    overflow: hidden;
    // width: 30%;
    margin-bottom: 15px;
    // height: 63px;
    .BlockLinkText {
      font-size: 2rem;
      padding: 10px 15px 10px 0;
    }
    @media (max-width: 360px) {
      width: 47.5%;
      height: 63px;

      .BlockLinkText {
        font-size: 18px;
        padding: 10px 0;
      }
    }
  }
  .inHeader {
    &.BlockLink {
      width: 100%;
      
      height: 86px;
      font-size: 22px;

      .BlockLinkText > p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 5px;
        line-height: 1em;
      }
      
      @media (max-width: 400px) {
        height: 80px; 
      }
      
      
      .BlockLinkText {
        height: 100%;
        padding: 15px 0;
        display: flex;
        justify-content: center;

        @media (max-width: 400px) {
          font-size: 22px;
          padding: 7.5px 0;
          line-height: 1em;
        }
      }

      overflow: hidden;
      margin-bottom: 15px;
    }

    .BlockLinkText {
      height: auto;
      padding: 27px 30px;
      padding-left: 0;
    }
    // max-width: 140px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 25px;
  }
}
