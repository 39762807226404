.wrapperMain {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #0c282b;
}
.headerWrapper {
  font-family: "Colbo-Ultra-bold", "Gotham";
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 60vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (max-width: 768px) {
    min-height: 44vh;
  }
  @media (min-width: 1921px) {
    video {
      width: 100vw;
      height: auto;
    }
  }
  &Title {
    font-family: "Colbo-LightExtended", "Gotham";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    //direction: rtl;
    color: #e7e7e7;
    top: 0;
    font-size: 29.6px;
    z-index: 10;
    padding-bottom: 130px;
    background: transparent
      linear-gradient(180deg, #000000b2 0%, #00000000 100%) 0% 0% no-repeat
      padding-box;
  }
}

.contentWrapper {
  margin: 0 auto;
  max-width: 1700px;
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.blockInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 290px;
  z-index: 2;
  background: linear-gradient(
    179.7deg,
    rgba(0, 0, 0, 0.0001) 16.85%,
    #0c282b 42.3%
  );
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  cursor: default;
  @media (min-width: 1921px) {
    bottom: unset;
    top: calc(50vh - 290px);
  }
  &Form {
    direction: rtl;
    max-width: 732px;
    @media (-webkit-min-device-pixel-ratio: 1.25) {
      max-width: 600px;
    }
    @media (max-width: 1440px) {
      max-width: 500px;
      margin: 0 auto;
      @media (-webkit-min-device-pixel-ratio: 1.25) {
        max-width: 400px;
      }
    }
  }

  .subTitle {
    font-family: "Colbo-LightExtended", "Gotham";
    font-size: 2rem;
    text-align: center;
    color: #fff;
  }

  .subTitleText {
    font-family: "Colbo-LightExtended", "Gotham";
    color: #fff;
    font-size: 1.7rem;
    text-align: center;
  }

  .formTitle {
    font-family: "Colbo-LightExtended", "Gotham";
    margin-top: 3vh;
    margin-bottom: 1.7vh;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 200;
    @media (max-width: 768px) {
      margin-top: 18vh;
      font-size: 24px;
    }
    br {
      display: none;
    }
  }
  @media (max-width: 1440px) {
    .formTitle {
      margin-top: 3vh;
      margin-bottom: 1vh;
    }
  }
  .formInput {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 14px;

    .dropDownItem {
      font-family: "Colbo-LightExtended", "Gotham";
      position: absolute;
      height: auto;
      width: 100%;
      bottom: 0;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      color: #0c282b;
      z-index: 1;
      border-radius: 0px 13px 0px 0px;
      > span {
        border-bottom: 1px solid lightgray;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        &:hover {
          background: #f5f5f5;
        }
      }
      &:nth-child(7) {
        border-bottom: none;
      }
    }

    > div {
      position: relative;
      max-width: 354px;
      width: 48%;
      @media (max-width: 768px) {
        width: 100%;
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 2;
        }
        &:nth-child(4) {
          order: 4;
        }
      }
      > span {
        font-family: "Colbo-LightExtended", "Gotham";
        position: absolute;
        right: 10px;
        top: 12px;
        color: #0c282b;
        @media (max-width: 768px) {
          font-family: "Colbo-LightSemiExtended", "Gotham";
          font-size: 24px;
        }
      }
    }
    > div > input {
      font-family: "Colbo-LightExtended", "Gotham";
      font-weight: 400;
      font-size: 16px;
      color: #0c282b;
      outline: none;
      max-width: 354px;
      width: 100%;
      height: 40px;
      padding: 15px;
      border: none;
      border-radius: 0px 13px 0px 0px;
      @media (max-width: 768px) {
        font-size: 24px;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-bottom: 5px;
      }
    }
    .fullName {
      padding-right: 68px !important;
    }
    .email {
      padding-right: 45px !important;
    }
    .phone {
      padding-right: 68px !important;
    }
    .optOut {
      padding-right: 52px !important;
    }
    @media (max-width: 768px) {
      .fullName {
        padding-right: 85px !important;
      }
      .email {
        padding-right: 62px !important;
      }
      .phone {
        padding-right: 85px !important;
      }
      .optOut {
        padding-right: 68px !important;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    padding-left: 11px;
    .buttonWrapperSecond {
      width: 50%;
      display: flex;
      justify-content: space-between;
      .checkboxWrapper {
        padding-right: 13px;
      }
    }

    .checkboxTitle {
      font-family: "Colbo-LightExtended", "Gotham";
      font-size: 16px;
      color: #fff;
      margin-right: 5px;
    }

    .checkboxButton {
      font-family: "Colbo-LightExtended", "Gotham";
      font-size: 30px;
      color: #0c282b;
      outline: none;
      border: none;
      background-color: #fdb913;
      padding: 1px 22px;
      // border-radius: 0px 13px 0px 0px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .buttonWrapperSecond {
        width: 100%;
        max-width: 90vw;
        margin: 0 auto;
        .checkboxWrapper {
          padding-right: 0;
        }
      }
      .checkboxButton {
        padding: 1px 17px;
      }
    }
  }
  .checkboxCreate {
    font-family: "Colbo-LightExtended", "Gotham";
    font-size: 15px;
    // display: flex;
    // justify-content: center;
    // margin-top: 27px;
    color: #fff;
    text-align: center;
    position: absolute;
    width: 99%;
    bottom: 0;
    left: 0;
    padding: 0 8rem 0 8rem;

    @media (max-width: 767px) {
      padding: 1rem 0 0 0;
      // bottom: 0rem;
      position: relative;

    }

  }

  .succsessSubmit {
    font-family: "Colbo-LightExtended", "Gotham";
    text-align: center;
    color: #ffffff;
    font-size: 48px;
    line-height: 37px;
    margin-top: 69px;
  }

  .wrapperLinks {
    display: flex;
    align-items: center;
    gap: 6.6vh;
    margin-left: auto;
    justify-content: center;
    @media (max-width: 1440px) {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
    }
    @media (max-width: 768px) {
      max-width: 450px;
    }
    &First {
      display: flex;
      flex-direction: column;
      gap: 3vh;
    }
    &Mob {
      display: none;
    }
  }
}
.footerButton{
  display: none;
  @media (max-width: 768px) {
    display: none;
    position: fixed;
    right: 15px;
    bottom: 10px;
    border-radius: 50px;
    background-color: #fdb913;
    width: 60px;
    height: 60px;
    padding: 15px;
    z-index: 999;
    svg path{
      fill: #fff;
      transform: rotate(270deg);
      transform-origin: center;
    }
  }
}
.cookieSuccses {
  direction: rtl;
  font-family: "Colbo-LightExtended", "Gotham";
  position: fixed;
  bottom: 0;
  background-color: rgba(255, 22, 80, 0.753);
  font-size: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #ffffff;
  font-size: 18.5px;
  z-index: 51;

  @media (max-width: 768px) {
    direction: rtl;
    font-family: "Colbo-LightExtended", "Gotham";
    position: fixed;
    bottom: unset;
    bottom: 0px;
    background-color: rgba(255, 22, 80, 1);
    font-size: 12px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #ffffff;
    z-index: 10050011;
    padding: 10px;
    border-radius: 0;
  }

  &Link {
    cursor: pointer;
    text-decoration: underline;
    margin-right: 15px;
  }
  &Button {
    background: #000;
    margin-right: 55px;
    padding: 9px 30px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.popUp {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;

  @media (max-width: 1024px) {
    overflow-y: scroll;
    height: calc(100vh - 120px);
    display: block;
    align-items: unset;
    justify-content: unset;
    top: 55px;
    left: 0;
    right: 0;
    bottom: unset;
  }
  a {
    color: #ffffff;
    text-decoration: underline solid blue;
  }

  &Block {
    position: relative;
    direction: rtl;
    background-color: #001a2d;
    color: #fff;
    padding: 60px 120px 60px 60px;
    max-width: 1360px;
    @media (max-width: 726px) {
      max-width: 100vw;
      padding: 10vh 5vw 0;
    }
  }
  &Title {
    font-family: "Colbo-Ultra-bold", "Gotham";
    font-size: 25px;
    margin-bottom: 30px;
    direction: rtl;
  }
  &Text {
    font-family: "Colbo-LightExtended", "Gotham";
    margin-bottom: 20px;
    font-size: 18px;
    direction: rtl;
  }
  .cross {
    position: absolute;
    right: 30px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    &One {
      position: absolute;
      width: 30px;
      height: 5px;
      background-color: #ffffff;
      transform: rotate(45deg);
    }
    &Second {
      width: 30px;
      height: 5px;
      background-color: #ffffff;
      transform: rotate(-45deg);
    }
  }
}

.error {
  position: absolute;
  color: #fff !important;
  margin-top: 20px !important;
  bottom: 100% !important;
  top: unset !important;
  left: 2px !important;
  font-family: sans-serif;
  font-size: 12px;
}

.fontPink {
  color: #fdb913;
}

.formMain {
  position: relative;
}

//----------------------------------------------------------------------------
.wrapperDoor {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  display: none;
  top: 0;
  z-index: 20;
  overflow-x: hidden;
  height: 100%;
  @media (max-width: 768px) {
    height: auto;
    position: relative;
  }
}
.wrapperDoor.active {
  display: flex !important;
}

@media (max-width: 810px) {
  .wrapperLinks {
    flex-direction: row;
    transform: none;
    margin: 0 auto;
  }

  .contentWrapper {
    max-height: unset;
    flex-direction: column;
  }

  .blockInfoForm {
    margin: 0 auto;
    margin-bottom: 75px;
  }
}

.closeFormButton {
  display: none;
}

.downSideButton {
  display: none;
}

@media (max-width: 768px) {
  .headerWrapper {
    position: absolute;
    height: 100%;
    max-height: 50vh;
    align-items: flex-start;
    iframe,
    video {
      width: auto;
      height: 50vh;
    }
  }
  .wrapperMain {
    height: 100%;
    max-height: unset;
    min-height: 100vh;
  }
  .wrapperLinks {
    &First {
      display: none !important;
    }
    &Mob {
      display: flex !important;
      width: 100%;
      max-width: 90vw;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
    }
  }
  .headerWrapperTitle {
    display: none;
  }
  .contentWrapper {
    justify-content: flex-start;
  }
  .blockInfo {
    position: absolute;
    top: 32vh;

    @media (max-width: 768px) {
      top: 28vh;
      position: relative;
    }

    @media (max-height: 715px) {
      top: 25vh;
    }

    margin: 0;
    padding: 6vh 0 0;
    background: linear-gradient(
      179.7deg,
      rgba(12, 40, 43, 0.0001) 0,
      #0c282b 16.85%
    );
  }

  .blockInfoForm {
    margin: 0 auto 20px;
    max-width: 365px;
    & form {
      top: 0;
      display: none;
      left: 100%;
      max-height: 100vh;
      max-width: 100vw;
      transition: left 0.3s ease-in-out;
      visibility: hidden;
      * {
        position: fixed;
      }
      &.formMain {
        position: fixed;
        display: block;
        height: 0;
        width: 0;
        visibility: hidden;
      }
      &.shownForm {
        visibility: visible;
        height: auto;
        width: 100%;
        position: relative;
        transform: translateX(0);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #e50d3f;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 100512;
        padding-right: 17px;
        padding-left: 19px;
        overflow: hidden;
        * {
          position: relative;
        }
        & .formTitle {
          margin-top: a;
          margin-bottom: 3.4vh;
          text-align: center;

          & br {
            display: inline;
          }
        }

        & .buttonWrapper {
          padding-top: 13px;
          padding-left: 0;
          flex-wrap: wrap;

          & svg {
            width: 45vw;
            margin: 0 auto;
            order: 1;
            display: block;
            margin-top: 4vh;
          }
        }

        & .checkboxButton {
          background-color: #0c282b;
          width: 86px;
          height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        & .checkboxCreate {
          display: none;
        }

        & .closeFormButton {
          position: absolute;
          right: 32px;
          top: 32px;
          width: 32px;
          height: 32px;
          display: block;
          background: transparent;
          &:hover {
            opacity: 1;
          }
          &:before,
          &:after {
            right: 0;
            top: 0;
            position: absolute;
            left: 15px;
            content: " ";
            height: 33px;
            width: 2px;
            background-color: rgb(255, 255, 255);
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }

        & .downSideButton {
          display: block;
          margin-top: 4vh;
          width: 90vw;
          height: 42px;
          color: #e50d3f;
          font-size: 23px;
          font-family: "Colbo-BoldExtended", "Gotham";
          margin-bottom: 5vh;
          margin-left: 5vw;
          line-height: 1.8em;
        }
      }
    }

    & .h1Svg {
      max-width: 90vw;
      width: 100%;
      display: block;
      margin: 0 auto;
    }

    .clock {
      left: 1vw;
      top: 48%;
      height: 13vw;
      transform: translateY(-50%);
      @media (max-width: 379px) {
        left: 1%;
      }
      @media (max-width: 369px) {
        left: 4.5%;
      }
      @media (max-width: 359px) {
        left: 6%;
      }
    }

    & .subTitle {
      font-family: "Colbo-LightExtended", "Gotham";
      font-size: 2em;
      max-width: 90vh;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    & .subTitleText {
      font-family: "Colbo-LightExtended", "Gotham";
      font-size: 1.6em;
      max-width: 75vw;
      margin: 0 auto;
    }
  }

  .wrapperDoor {
    flex-direction: column-reverse;
    min-height: unset;
  }
  .tabs {
    display: none !important;
  }
}
.flexAroud {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  margin-top: 25vh !important;
}
.zIndex {
  z-index: 9999;
}
.object {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.wrapperLinksMob {
  // display: grid !important;
  // grid-template-columns: repeat(2, 1fr); /* 2 items per row */
}