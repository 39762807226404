.header {
  display: none;
  background-color: #fdb913;
  padding: 15px 18px;
  padding-bottom: 15px;
  min-height: 55px;
  & > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .header {
    z-index: 100;
    display: flex;
    justify-content: space-between;
    direction: ltr;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    &.cookieSuccsesHeader{
      top: 67px;
    
      .tWrapp {
        top: 82px;
      }
    }
  }
  .tWrapp {
    position: fixed;
    right: 18px;
    top: 18px;
    height: 22px;
  }

  .toggler {
    order: -1;
    display: block;
    margin-left: auto;
    background-color: transparent;
    border: none;
    width: 30px;
    height: auto;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #0c282b;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2) {
        top: 9px;
      }
      &:nth-child(3) {
        top: 18px;
      }
    }
  }

  .menuLogo {
    display: none;
  }

  .menuOverlay {
    width: auto;
    height: auto;
    background-color: transparent;
    direction: rtl;

    .list {
      display: none;
    }
  }

  .menuOverlayOpen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0c282b;
    z-index: 100500;
    padding: 40px 26px;
    overflow-x: auto;
    padding-bottom: 6rem;

    animation-name: floatMenu;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-direction: normal;

    .menuLogo {
      display: block;
      margin: 0 auto;
      width: 100px;
      transform: scale(1.6);
      margin-top: 50px;
    }

    .navButton {
      display: block;
      margin: 6vh auto;
      font-size: 23px;
      width: 7em;
      text-align: center;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      max-width: 320px;
      margin: 0 auto;

      justify-content: space-between;
    }

    & .toggler span {
      &:nth-child(1) {
        top: 18px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 18px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }

  .menuOverlayClosing {
    animation-name: floatMenuRev;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-direction: normal;
  }
}

@media (max-width: 360px) {
  .header {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    direction: ltr;
  }

  .menuLogo {
    display: none;
  }

  .menuOverlay {
    width: auto;
    height: auto;
    background-color: transparent;
    direction: rtl;

    .list {
      display: none;
    }
  }

  .menuOverlayOpen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fdb913;
    z-index: 100500;
    padding: 40px 26px;

    animation-name: floatMenu;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-direction: normal;

    .menuLogo {
      display: block;
      margin: 0 auto;
      width: 100px;
      transform: scale(1.6);
      margin-top: 50px;
    }

    .navButton {
      font-size: 16px;
      line-height: 1.6em;
      padding: 5px 15px;
      min-height: unset;
      display: flex;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      max-width: 320px;
      margin: 0 auto;

      justify-content: space-between;
    }
  }

  .menuOverlayClosing {
    animation-name: floatMenuRev;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-direction: normal;
  }
}

.navButton {
  border-top-left-radius: 14px;
  background-color: #fdb913;
  display: block;
  margin: 0 auto;
  padding: 13px 21px;
  padding-bottom: 17px;
  font-family: "Colbo-BoldExtended";
  color: #0c282b;
  display: none;
  width: 146px;
  min-height: 52px;
}

@keyframes floatMenu {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes floatMenuRev {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
