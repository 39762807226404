.buttonsWrapper {
  display: none;
}
@media (max-width: 768px) {
  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100vw;
    padding: 20px 5vw;
    margin: 0 auto;
    margin-top: 47px;
    direction: rtl;
    z-index: 100500;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(#0c282b00, #0c282be3, #0c282b);
  }
  .footerButton {
    background-color: #fdb913;
    padding: 10px 20px 12px;
    font-family: "Colbo-BoldExtended", "Gotham";
    color: #fff;
    font-size: 20px;
    @media (max-width: 400px) {
      padding: 10px 30px;
    }
  }
}
